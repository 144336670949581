/**
 * @author Basit Raza <basitraza@vizzwebsolutions.com>
 * @link http://be.net/basit_raza Author Website
 * @since 2020-10-31
 */

import Swal from 'sweetalert2/dist/sweetalert2.js'

function checkForAPIFailResponse (response){
  try{
    if('success' in response && response.success === false){
      let message = ('message' in response)? response.message: 'Something not fine there..';
      Swal.fire({
        title: 'Oops',
        text: message,
        icon: 'error'
      })
      return false
    }
    else{
      return true
    }
  }
  catch(e){
  	console.log(e);
    Swal.fire({
      title: 'Oops',
      text: 'Something gone wrong..',
      icon: 'error'
    })
    return false;
  }
}

export {
  checkForAPIFailResponse
}
